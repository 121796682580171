import aus from "../assets/images/aus.png";
import can from "../assets/images/can.png";
import ind from "../assets/images/ind.png";
import indonesia from "../assets/images/indonesia.png";
import ire from "../assets/images/ire.png";
import kenya from "../assets/images/kenya.png";
import kuwait from "../assets/images/kuwait.png";
import malasia from "../assets/images/malasia.png";
import oman from "../assets/images/oman.png";
import pak from "../assets/images/PAK.png";
import qatar from "../assets/images/qatar.png";
import nz from "../assets/images/nz.png";
import Usa from "../assets/images/U.S.A.png";
import uk from "../assets/images/U.K.png";
import SA from "../assets/images/SA.png";
import uae from "../assets/images/U.A.E.png";
import cet from "../assets/images/cet.png";
import singapur from "../assets/images/singapur.png";
export const timeZoneList = [
  {
    timeZones: "Australia (ACST)",
    value: "Australia/Adelaide",
    img: aus,
  },
  {
    timeZones: "Australia (AEST)",
    value: "Australia/Brisbane",
    img: aus,
  },
  {
    timeZones: "Australia (AEDT)",
    value: "Australia/Melbourne",
    img: aus,
  },
  {
    timeZones: "Australia (AWST)",
    value: "Australia/West",
    img: aus,
  },
  {
    timeZones: "Canada (AST)",
    value: "Canada/Atlantic",
    img: can,
  },
  {
    timeZones: "Canada (CST)",
    value: "Canada/Central",
    img: can,
  },
  {
    timeZones: "Canada (EST)",
    value: "Canada/Eastern",
    img: can,
  },
  {
    timeZones: "Canada (MST)",
    value: "Canada/Mountain",
    img: can,
  },
  {
    timeZones: "Canada (PST)",
    value: "Canada/Pacific",
    img: can,
  },
  {
    timeZones: "Canada (NST)",
    value: "Canada/Newfoundland",
    img: can,
  },
  {
    timeZones: "Central European Time (CET)",
    value: "CET",
    img: cet,
  },
  {
    timeZones: "India (IST)",
    value: "Asia/Calcutta",
    img: ind,
  },
  {
    timeZones: "Indonesia (WST)",
    value: "Asia/Jayapura",
    img: indonesia,
  },
  {
    timeZones: "Indonesia (WIB)",
    value: "Asia/Jakarta",
    img: indonesia,
  },
  {
    timeZones: "Ireland (IST)",
    value: "Eire",
    img: ire,
  },
  {
    timeZones: "Kenya (EAT)",
    value: "Africa/Asmera",
    img: kenya,
  },
  {
    timeZones: "Kuwait (AST)",
    value: "Asia/Kuwait",
    img: kuwait,
  },
  {
    timeZones: "Malaysia (MYT)",
    value: "Asia/Kuala_Lumpur",
    img: malasia,
  },
  {
    timeZones: "New Zeland (NZ)",
    value: "NZ",
    img: nz,
  },
  {
    timeZones: "New Zeland (NZ-CHAT)",
    value: "NZ-CHAT",
    img: nz,
  },
  {
    timeZones: "Qatar (AST)",
    value: "Asia/Qatar",
    img: qatar,
  },
  {
    timeZones: "Singapore (SGT)",
    value: "Asia/Singapore",
    img: singapur,
  },
  {
    timeZones: "South Africa (SAST)",
    value: "Africa/Johannesburg",
    img: SA,
  },
  {
    timeZones: "UAE (GST)",
    value: "Asia/Dubai",
    img: uae,
  },
  {
    timeZones: "UK (BST)",
    value: "Europe/London",
    img: uk,
  },
  {
    timeZones: "USA (CST)",
    value: "US/Central",
    img: Usa,
  },
  {
    timeZones: "USA (EST)",
    value: "US/Eastern",
    img: Usa,
  },
  {
    timeZones: "USA (MST)",
    value: "US/Mountain",
    img: Usa,
  },
  {
    timeZones: "USA (PST)",
    value: "US/Pacific",
    img: Usa,
  },
  {
    timeZones: "Pakistan (PKT)",
    value: "Asia/Karachi",
    img: pak,
  },
  {
    timeZones: "Oman (OMT)",
    value: "Asia/Muscat",
    img: oman,
  },
];
